
.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    font-size: 14px;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    $parent: &;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 700;
    transition: all 0.15s ease-in-out;
    white-space: nowrap;
    color: $snowy;
    background-image: linear-gradient(95.92deg, #D93C92 2.64%, #C121DE 109.06%);
    position: relative;
    &:before {
        content: '';
        width: 80%;
        bottom: -10%;
        height: 20%;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        background-image: linear-gradient(95.92deg, #D93C92 2.64%, #C121DE 109.06%);
        filter: blur(8px);
        transition: all 0.2s ease-in-out;
    }
    &:hover {
        &:before {
            filter: blur(6px);
            transform: translateY(-8px);
        }
    }
}
