.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  opacity: 1;
  $parent: &;
  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
  &--overlayed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $snowy;
    z-index: 1000;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(50% 50% at 50% 50%, rgba(60, 43, 253, 0.6) 0%, rgba(21, 21, 21, 0) 100%);
      z-index: 1;
      animation-name: gradient;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    #{$parent}__icon {
      position: relative;
      z-index: 2;
    }
    #{$parent}__count {
      position: absolute;
      z-index: 3;
      font-size: 86px;
      font-weight: 700;
      line-height: 1;
      color: $snowy;
    }
  }
  &:not(#{$parent}--overlayed) {
    #{$parent}__icon {
      animation-name: rotating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      width: 36px;
      height: 36px;
    }
  }
}

@keyframes rotating {
  0% {
    transform: rotateX(0) rotateY(0)
  }
  25% {
    transform: rotateX(0deg) rotateY(180deg)
  }
  50% {
    transform: rotateX(180deg) rotateY(180deg)
  }
  75% {
    transform: rotateX(180deg) rotateY(359deg)
  }
  100% {
    transform: rotateX(359deg) rotateY(359deg)
  }
}

@keyframes gradient {
  0% {
    transform: rotate(0) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: rotate(180deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(359deg) scale(0.5);
    opacity: 0;
  }
}