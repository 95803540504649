@tailwind base;
@tailwind components;
@tailwind utilities;

$gray: #ECECEC;
$dark: #1B1B21;
$red: #FE6475;
$snowy: #E5E5E5;

@import './include-media.scss';
$breakpoints: (phone: 320px, landscape: 499px, tablet: 900px, desktop: 1280px, lgDesktop: 1600px);
@import './common/Input/index.scss';
@import './common/Button/index.scss';
@import './common/Preloader/index.scss';
@import './common/Responser/index.scss';

body {
  margin: 0;
  font-family: 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-dark text-snowy;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
