.input {
    @include media('>=phone', '<landscape') {
        width: 100%;
        margin-bottom: 10px;
    }
    border: 1px solid transparent;
    border-radius: 8px;
    outline: none;
    border-right: 0;
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
    font-weight: 500;
    padding: 12px 24px;
    background: $gray;
    color: $dark;
    &--error {
        border: 1px solid $red;
        &::placeholder {
            color: $red
        }
    }
    &:focus {
        filter: drop-shadow(0 0 5px $dark);
    }
}